import React, { useState } from 'react';
import img07 from './img/img07.jpg';
import './First.css';
import axios from 'axios';

function Third() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        try {
            await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/message`, {
                name,
                email,
                mobile,
                address,
                message
            });
            alert('Message has been send');
        } catch (error) {
            console.log(error);
            alert('Message has failed');
        }
    };

    return (
        <section className="First" style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${img07})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            color: '#fff',
            paddingTop: '300px',
            position: ''
        }}>
            <div className="container">
                <div className="Magic text-center">
                    <h3>The Best Cleaning Service Ever! </h3>
                    <h1 >We Are Certified Company </h1>
                    <p >
                        We specialize in delivering top-quality, meticulous cleaning solutions that guarantee your bond refund, making your move-out process stress-free.
                    </p>
                </div>
                <div className="Request">
                    <h2>Request an Estimate </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="RequestForm">

                            <input type="text" id="name" name="name" placeholder='Enter your FullName' value={name} onChange={(event) => setName(event.target.value)} />
                            <input type="email" id="email" name="email" placeholder='Enter your Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            <input type="text" id="phone" name="phone" placeholder='Enter your Phone number' value={mobile} onChange={(event) => {
                                const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                setMobile(numericValue);
                            }} />

                            <button type="submit">Submit</button>
                            {error && <div className="error-message">{error}</div>}
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Third;
