import React, { useState, useEffect } from "react";

import { useNavigate } from 'react-router-dom';
import './panel.css';

const Panel = () => {
    const navigate = useNavigate();

    const handleAboutUsClick = () => {
        navigate('/about-us');
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    return (
        <section>
            <div className="col-auto">
                <div className="panelbar text-center text-md-left">

                    <nav className='d-flex flex-column align-items-start py-md-2'>
                        <div className="list-holder mb-5 mb-md-0">
                            <ul className="nav-list">
                                <li className='mb-4'>
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className='mb-4'>
                                    <a href="/login" className="nav-link">login</a>
                                </li>
                                <li className='mb-4'>
                                    <a href="/BlogForm" className="nav-link">BlogForm</a>
                                </li>
                                <li className='mb-4'>
                                    <a href="/Panel" className="nav-link">Message</a>
                                </li>
                                <li className='mb-4'>
                                    <a className="nav-link" href="/BlogDisplay">BlogDisplay</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default Panel