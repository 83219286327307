import React, { useState, useEffect } from "react";
import './Quoute.css'
import { useNavigate } from 'react-router-dom';


const Quoute1 = () => {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/contact');
    };

    return (
        <div className="bubble col-lg-12">
            <div className="container">
                <div className="row">
                    <div className="bubble-overlay">
                        <h1 className="bubble-heading">
                            Ensure your space gleams with our top-tier cleaning services.
                            Get in touch with us today for a home that sparkles!
                        </h1>
                        <button onClick={handleContact}>Get Quote</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quoute1