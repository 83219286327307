import React from 'react'
import Slider from "react-slick";

import './Projects.scss'

const SLIDER_SETTINGS = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};

const SLIDES_DATA = [
  {
    id: 0,
    title: 'Steam Cleaning',
    imgSrc: 'images/img10.jpg',
    text: ' Our company employs advanced steam cleaning techniques to revitalize upholstery, ensuring a deep and thorough cleaning experience.',
  },
  {
    id: 1,
    title: 'window cleaning',
    imgSrc: 'images/img02.jpg',
    text: 'Our expert team employs professional-grade cleaning solutions to remove dirt, smudges, and water stains.',
  },
  {
    id: 2,
    title: 'Carpet cleaning',
    imgSrc: 'images/img11.jpg',
    text: 'we use  cutting-edge technology to lift and eliminate stubborn stains, dirt, and allergens, ensuring a refreshed and hygienic living environment.',
  },
  {
    id: 3,
    title: 'House cleaning',
    imgSrc: 'images/img04.jpg',
    text: 'Experience a spotless home with our comprehensive house cleaning service, delivering meticulous attention.'
  },
]

const ProjectSlide = ({ imgSrc, title, text }) => {
  return (
    <div className="slide">
      <div className="slide-content">
        <div className="overflow-hidden">
          <div className="img-holder" style={{ backgroundImage: `url(${imgSrc})`, paddingTop: '80%' }}>
          </div>
        </div>
        <div className="text-wrap px-4 py-3">
          <h3 className='text-capitalize mb-0'>{title}</h3>
          <span>{text}</span>
        </div>
      </div>
    </div>
  )
}

const Projects = () => {
  return (
    <section id="projects" className='py-5' style={{ backgroundColor: 'rgb(103, 103, 235)' }}>
      <div className="container">
        <div className="block-header text-center text-capitalize text-white mb-5">
          <h2>Keep your vision to our <br /> latest projects</h2>
        </div>
        <div className="content-holder pt-5">
          <Slider {...SLIDER_SETTINGS}>
            {
              SLIDES_DATA.map(slide => <ProjectSlide key={slide.id} {...slide} />)
            }
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Projects