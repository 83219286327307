import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WysiwygEditor = ({ onContentChange }) => {
    const handleChange = (html) => {
        onContentChange(html);
    };

    return (
        <ReactQuill
            theme="snow"
            onChange={handleChange}
        />
    )
}

export default WysiwygEditor;