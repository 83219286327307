import React, { useState } from 'react';
import service from '../img/service.jpg'
import axios from 'axios';
import '../CSS/navbar.css';
import { User, Mail, Phone, MapPin } from 'react-feather';


function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        try {
            await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/message`, {
                name,
                email,
                mobile,
                address,
                message
            });
            alert('Message has been send');
        } catch (error) {
            console.log(error);
            alert('Message has failed');
        }
    };

    return (
        <div>
            <section className="hero-section" style={{ backgroundImage: `url(${service})`, backgroundSize: 'cover', backgroundPosition: '50% 50%' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 py-5">
                            <div className="form-inside">
                                <form onSubmit={handleSubmit}>
                                    <h1> <b>CONTACT US</b></h1>
                                    <div className="input-group">
                                        <User size={20} color="blue" /> <b> Enter Your Name</b>
                                        <input type="text" id="name" name="name" placeholder='Enter your FullName' value={name} onChange={(event) => setName(event.target.value)} />
                                    </div>
                                    <div className="input-group">
                                        <Mail size={20} color="blue" /> <b> Enter Your E-Mail</b>
                                        <input type="email" id="email" name="email" placeholder='Enter your Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                    <div className="input-group">
                                        <Phone size={20} color="blue" /> <b> Enter Your Number</b>
                                        <input type="text" id="phone" name="phone" placeholder='Enter your Phone number' value={mobile} onChange={(event) => {
                                            const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                            setMobile(numericValue);
                                        }} />
                                    </div>
                                    <div className="input-group">
                                        <MapPin size={20} color="blue" /> <b> Enter Your address</b>
                                        <input type="text" id="address" name="address" placeholder='Enter your address' value={address} onChange={(event) => setAddress(event.target.value)} />
                                    </div>

                                    <textarea id="message" placeholder='Enter Message' name="message" rows="4" style={{ width: "100%" }} value={message} onChange={(event) => setMessage(event.target.value)}></textarea>

                                    <button type="submit">Submit</button>
                                    {error && <div className="error-message">{error}</div>}
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1635.4907486316977!2d138.55149853874516!3d-34.93200099424672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c583cbc43881%3A0xdc8c918b4062b0d9!2s89%20Marion%20Rd%2C%20Cowandilla%20SA%205033%2C%20Australia!5e0!3m2!1sen!2snp!4v1701143125996!5m2!1sen!2snp"
                    width="100%" height="800" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default Contact;
