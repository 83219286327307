import React, { useState, useEffect } from "react";
import axios from 'axios';

import Card from 'react-bootstrap/Card';
import './Blog.css'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState('');

    const bigBlog = (id) => {
        navigate(`/BigBlog/${id}`);
    };
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ROUTE}/api/blog`);
                setBlogs(response.data.blogs);
            } catch (error) {
                console.error("Error fetching blogs:", error);
                // Handle error state or display an error message to the user
            }
        };

        fetchBlogs();
    }, []);
    // Empty dependency array to run the effect only once on mount

    const getCategoryImage = (category) => {
        switch (category) {
            case 'carpet cleaning':
                return 'images/img11.jpg';
            case 'window cleaning':
                return 'images/img02.jpg';
            case 'Steam cleaning':
                return 'images/img10.jpg';
            case 'House cleaning':
                return 'images/img04.jpg';
            // Add more cases for other categories
            default:
                return 'images/img03.jpg'; // Default image if category is not recognized
        }
    };

    const handleCategoryClick = async (category) => {
        setSelectedCategory(category);

        console.log('Selected category:', category);
    };

    const handleShowAllClick = () => {
        setSelectedCategory('');
    };

    return (
        <main id="blog">
            <div className="side-search">
                <div className="search-bar">
                    <Form>
                        <Form.Control type="text" placeholder="Search..." />
                    </Form>
                </div>
                <div className="search-icon">
                    <Button variant="light-blue" type="submit">
                        <SearchIcon />
                    </Button>
                </div>
            </div>
            <div className="category-dropdown">
                <button className="category-button" onClick={handleShowAllClick}>
                    Show All
                </button>
                <div className="dropdown">
                    <button className="category-button dropdown-button">
                        {selectedCategory || 'Select Category'}
                    </button>
                    <div className="dropdown-content">
                        <button
                            className={`category-button ${selectedCategory === 'carpet cleaning' && 'active'}`}
                            onClick={() => handleCategoryClick('carpet cleaning')}
                        >
                            Carpet Cleaning
                        </button>
                        <button
                            className={`category-button ${selectedCategory === 'window cleaning' && 'active'}`}
                            onClick={() => handleCategoryClick('window cleaning')}
                        >
                            Window Cleaning
                        </button>
                        <button
                            className={`category-button ${selectedCategory === 'Steam cleaning' && 'active'}`}
                            onClick={() => handleCategoryClick('Steam cleaning')}
                        >
                            Steam Cleaning
                        </button>
                        <button
                            className={`category-button ${selectedCategory === 'House cleaning' && 'active'}`}
                            onClick={() => handleCategoryClick('House cleaning')}
                        >
                            House Cleaning
                        </button>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {[...blogs]
                        .filter(blog => selectedCategory === '' || blog.category === selectedCategory)
                        .reverse().map((blog, index) => (
                            <Card key={index} className=" blog-card col-lg-5 col-12">
                                <Card.Img
                                    className="blog-card-image"
                                    variant="top"
                                    src={getCategoryImage(blog.category)}
                                    alt={blog.category} />
                                <Card.Body className="blog-body">
                                    <p className="blog-category">
                                        {blog.category}
                                    </p>
                                    {/* <p>{blog.id}</p> */}
                                    <Card.Title><h2>{blog.title.length > 24
                                        ? blog.title.slice(0, 24) + "..."
                                        : blog.title}</h2></Card.Title>
                                    <Card.Text>
                                        <h5><b>Author:</b> {blog.author}</h5>
                                    </Card.Text>
                                    <motion.div>
                                        <button className="custom-button" onClick={() => bigBlog(blog.id)}>Read More </button>
                                    </motion.div>
                                </ Card.Body>
                            </Card>
                        ))}
                </div>
            </div>

        </main>
    );
};

export default Blog;
