import React, { useState, useEffect } from "react";
import './Quoute.css'
import { useNavigate } from 'react-router-dom';


const Quoute2 = () => {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/contact');
    };

    return (
        <div className="bubble">
            <div className="container">

                <div className="row">
                    <div className="bubble-overlay">
                        <h1 className="bubble-heading">
                            Experience a hassle-free bond cleaning with EasyBond Clean, offering competitive rates for a stress-free and efficient service.
                        </h1>
                        <button onClick={handleContact}> Get Quote</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quoute2