import React, { useState } from 'react';
import axios from 'axios';
import { User, Mail, Phone, MapPin } from 'react-feather';
import background from './img/background.jpg';
import './First.css';


function First() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const backgroundStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        try {
            await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/message`, {
                name,
                email,
                mobile,
                address,
                message
            });
            alert('Message has been send');
        } catch (error) {
            console.log(error);
            alert('Message has failed');
        }
    };

    return (

        <section className="fixed-background content-section" style={backgroundStyle}>
            <div className="row first-content content-container">
                <div className="col-lg-6 col-12 py-5 overlay">
                    <h1 >Easy Bond Cleaning Services: Transforming Spaces with Immaculate Cleanliness</h1>
                    <p >We specialize in delivering top-quality, meticulous cleaning solutions that guarantee your bond refund, making your move-out process stress-free.

                    </p>
                </div>
                <div className="col-lg-6 col-12 py-5 first-form">
                    <form onSubmit={handleSubmit}>
                        <h1> <b>Estimate With Message</b></h1>
                        <div className="input-group">
                            <input type="text" id="name" name="name" placeholder='Enter your FullName' value={name} onChange={(event) => setName(event.target.value)} />
                        </div>
                        <div className="input-group">
                            <input type="email" id="email" name="email" placeholder='Enter your Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                        </div>
                        <div className="input-group">
                            <input type="text" id="phone" name="phone" placeholder='Enter your Phone number' value={mobile} onChange={(event) => {
                                const numericValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                setMobile(numericValue);
                            }} />
                        </div>

                        <textarea id="message" placeholder='Enter Message' name="message" style={{ width: "100%" }} value={message} onChange={(event) => setMessage(event.target.value)}></textarea>
                        <button type="submit">Submit</button>
                        {error && <div className="error-message">{error}</div>}
                    </form>


                </div>
            </div>
        </section >
    )
}

export default First;
