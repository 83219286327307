import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WysiwygEditor from '../../components/WysiwygEditor'
import Panel from "../../components/Panel/Panel";
import './BlogForm.css';


const BlogForm = () => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [blog, setBlogContent] = useState('');
    const [category, setCategory] = useState('default');

    const handleContentChange = (content) => {
        setBlogContent(content);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');
            await axios.post(process.env.REACT_APP_API_ROUTE + '/api/blog', {
                title,
                author,
                blog,
                category,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setTitle('');
            setAuthor('');
            setBlogContent('');
            setCategory('');
            toast.success('Blog has been successfully updated');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update blog');
        }
    };


    return (
        <main id="example">
            <Panel />
            <div className="col-10">
                <label>Title:</label>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />

                <label>Author:</label>
                <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} />
                <label>Category:</label>
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="default">Default</option>
                    <option value="carpet cleaning">carpet cleaning</option>
                    <option value="window cleaning">window cleaning</option>
                    <option value="Steam cleaning">Steam cleaning</option>
                    <option value="House cleaning">House cleaning</option>
                </select>
                <label>Blog Content:</label>
                <WysiwygEditor onContentChange={handleContentChange} />
                <div className="swig">
                    <button onClick={handleSubmit}>Submit</button>
                </div>
                <ToastContainer />
            </div>
        </main>
    );
};

export default BlogForm;