import React from 'react'

import Newsletter from '../Newsletter';
import './Footer.scss'

const Footer = () => {
  return (
    <footer className='footer py-5' style={{ backgroundColor: '#F2F6FF' }}>
      <div className="container">
        <div className="footer-content row">
          <div className="col-md-3">
            <div className="block-header text-capitalize">
              <h3>Contact Us</h3>
            </div>
            <address>89 Marion RD Cowandilla 5033</address>
            <a href="mailto:quotes@gsbondcleaning.com.au">sales@easybondclean.com.au</a>
          </div>
          <div className="col-md-3 ">
            <div className="block-header text-capitalize">
              <h3>Our Links</h3>
            </div>
            <div className="list-holder">
              <ul className="link-list text-capitalize">
                <li><a href="/">home</a></li>
                <li><a href="/about-us">about</a></li>
                <li><a href="/blog">blog</a></li>
                <li><a href="/contact">contact us</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="block-header text-capitalize">
              <h3>Our Services</h3>
            </div>
            <div className="list-holder">
              <ul className="services-list text-capitalize">
                <li>Power/Pressure Cleaning</li>
                <li>Bond Cleaning</li>
                <li>Window Cleaning</li>
                <li>Carpet Cleaning</li>
                <li>Upholstery Cleaning</li>
                <li>End of lease cleaning</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 ">
            <Newsletter />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer