import React from 'react';
import Slider from 'react-slick';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.scss';

const TestimonialSlide = ({ testimonial, user }) => {
  return (
    <div className="slide">
      <div className="icon-holder">
        <FormatQuoteIcon fontSize='inherit' />
      </div>
      <div className="slide-content col-">
        <p>{testimonial}</p>
        <strong className='text-lg'>{user}</strong>
      </div>
    </div>
  );
};

const Testimonial = () => {
  const SLIDER_SETTINGS = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const SLIDES_DATA = [
    {
      id: 0,
      user: 'Sophie Mitchell',
      testimonial: 'The attention to detail is unmatched, and the team consistently delivers impeccable service. They not only meet but exceed expectations, leaving my space spotless and refreshed',
    },
    {
      id: 1,
      user: 'Jack Thompson',
      testimonial: 'Reliable and Trustworthy Team. Their team is not only skilled in their cleaning techniques but also incredibly reliable. '
    },
    {
      id: 2,
      user: 'Emma Williams',
      testimonial: 'What sets this cleaning service apart is their innovative approach to cleaning. From eco-friendly products to advanced techniques, they bring a fresh perspective to the cleaning process. ',
    },
    {
      id: 3,
      user: 'Liam Smith',
      testimonial: 'The customer service provided by this company is truly exceptional. ',
    },
  ]
  return (
    <section id='testimonials' className='py-5' style={{ backgroundColor: 'rgb(103, 103, 235)' }}>
      <div className="container">
        <div className="block-header text-center text-white mb-5">
          <h2>Providing the Best Services<br />for our Customers</h2>
        </div>
        <div className="content-holder pb-5">
          <Slider {...SLIDER_SETTINGS}>
            {SLIDES_DATA.map((slide) => (
              <TestimonialSlide key={slide.id} {...slide} />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;