import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Select, Text, Input, Button } from '@chakra-ui/react';
import './admin-login.css';


const AdminLogin = () => {
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState('');
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        console.log(`${process.env.REACT_APP_API_ROUTE}/api/login`)
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}/api/login`, {
                username,
                password,
            });
            localStorage.setItem('accessToken', response.data.accessToken);
            setMessage(`User with ID ${response.data.userId} has successfully logged in`);
            navigate("/Panel");
            setAccessToken(response.data.token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        } catch (error) {
            console.error(error);
            setMessage('Invalid username or password');
        }
    };
    return (
        <section id='adminLogin'>
            <div className="container">
                <h1>ADMIN LOGIN</h1>
                <form onSubmit={handleSubmit}>

                    <div className='input-group w-full'>
                        <label htmlFor="username">Username:</label>
                        <Input type="text" id="username" value={username} onChange={handleUsernameChange} />
                    </div>
                    <div className='input-group w-full'>
                        <label htmlFor="password">Password:</label>
                        <Input type="password" id="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <div className="button-group w-full mb-md">
                        <Button colorScheme='Blue' type="submit" className='w-full uppercase'>Log in</Button>
                    </div>
                </form>
                <div className="notification text-center">
                    {accessToken && <p>Access Token: {accessToken}</p>}
                    {message && <p>{message}</p>}
                </div>
            </div>
        </section>
    );
}

export default AdminLogin;