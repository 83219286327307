import React, { useState, useEffect } from "react";
import axios from 'axios';
import Panel from "../../components/Panel/Panel";
import Card from 'react-bootstrap/Card';
import './message.css';

const Message = () => {
    const [wessage, setWessage] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ROUTE}/api/message`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                });
                setWessage(response.data.wessage);
            } catch (error) {
                console.error("Error fetching messages:", error);
                // Handle error state or display an error message to the user
            }
        };

        fetchMessages();
    }, []); // Empty dependency array to run the effect only once on mount

    const toggleCollapsible = (index) => {
        const content = document.getElementById(`content${index}`);
        if (content.style.display === "block") {
            content.style.display = "none";
        } else {
            content.style.display = "block";
        }
    };

    return (
        <main id="example">
            <Panel />
            <div className="col-11">
                <h1>Random Messages</h1>
                <div className="row">
                    {wessage.map((item, index) => (
                        <div key={index} className="col-5 mb-4">
                            <Card>
                                <Card.Header>
                                    <button
                                        className="collapsible btn btn-link"
                                        onClick={() => toggleCollapsible(index)}
                                    >
                                        Show Message {index + 1}
                                    </button>
                                </Card.Header>
                                <div id={`content${index}`} className="content" style={{ display: "none" }}>
                                    <Card.Body>
                                        <p>Name: {item.name}</p>
                                        <p>Email: {item.email}</p>
                                        <p>Mobile: {item.mobile}</p>
                                        <p>Address: {item.address}</p>
                                        <p>Message: {item.message}</p>
                                    </Card.Body>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
};

export default Message;
