import React, { useState, useEffect } from "react";
import './Text1.css'
import { CheckCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const Text1 = () => {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/contact');
    };
    return (
        <div class="text-center py-5 text1" style={{ backgroundColor: '#F2F6FF' }}>
            <div className="container">
                <div className="Text1">
                    <h4> Best Experts in Cleaning</h4>
                    <h1> Your Happiness Is Our First Priority</h1>
                    <p>At Easy Bond Clean, we stand as a beacon of excellence in the cleaning industry, emphasizing a commitment to meticulous and thorough cleaning services. Our dedicated team understands the importance of a pristine living or working space, especially when it comes to bond cleaning.</p>
                    <p>Customer satisfaction is not just a goal but a guiding principle for us. We prioritize understanding and meeting the unique needs of our clients. From the initial inquiry to the final walkthrough, our focus remains on ensuring a seamless and satisfying experience. We believe that a clean environment contributes significantly to the overall satisfaction and well-being of our customers.</p>

                    <p>We believe in leaving no corner untouched. Our process includes a comprehensive inspection, ensuring that every nook and cranny meets our high standards of cleanliness. This meticulous approach guarantees that your property not only meets but exceeds the expectations of landlords or property managers during bond inspections.</p>

                    <p>Open and transparent communication is the bedrock of our service philosophy. Before we commence any cleaning project, we provide clear estimates and detailed explanations of the services involved. We want our clients to have a complete understanding of the process, fostering trust and confidence in our ability to deliver exceptional results.</p>

                    <p>While bond cleaning is our specialty, our expertise extends beyond. We offer a range of cleaning services to cater to various needs, ensuring that homes and commercial spaces receive the care and attention they deserve. From carpet cleaning to general maintenance, we are your one-stop solution for a clean and welcoming space.</p>
                    <ul>
                        <li>  <CheckCircle size={32} color="green" />Trusted Cleaners  </li>
                        <li>  <CheckCircle size={32} color="green" />Best Quality Job</li>
                        <li>  <CheckCircle size={32} color="green" />On Time and Steady</li>
                        <li>  <CheckCircle size={32} color="green" />24/7 Support Team</li>
                    </ul>
                    <h1>Enquire now to get esteemed budget for our services. </h1>
                    <button onClick={handleContact} > Get in Touch </button>
                </div>
            </div>
        </div>
    )
}

export default Text1;