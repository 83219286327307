import React from 'react';
import './Choose.css'
import { motion } from 'framer-motion'
import why from '../img/why-us.jpg'

const Choose = () => {
    return (
        <section id="choose" className='text-center py-5' style={{ backgroundColor: '#F2F6FF' }}>
            <div className="container">
                <div className='row' >
                    <div className='col-lg-6 col-12 about-why'>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: 0.3 }}
                            variants={{
                                visible: { opacity: 1, scale: 1 },
                                hidden: { opacity: 0, scale: 0 }
                            }}
                        >
                            <h4>Cleaning expertise for customer satisfaction</h4>
                            <h1><b>Why Choose Us For Bond Cleaning?</b></h1>
                        </motion.div>

                        <p>Easy Bond Cleaning specializes in professional end-of-lease and bond cleaning services,
                            ensuring a seamless transition for both tenants and property owners.
                            Our experienced team uses eco-friendly products and meticulous techniques to deliver impeccable results,
                            meeting the high standards set by landlords and real estate agents. Whether you're a tenant or a property owner,
                            we aim to make the end-of-lease process stress-free and efficient.</p>
                        <ul class="point-list">
                            <li> Professional End-of-Lease Cleaning: Easy Bond Clean specializes in providing professional and thorough end-of-lease cleaning services for tenants,
                                ensuring properties are left immaculate at the end of a tenancy.</li>
                            <li>Experienced and Dedicated Team: The company boasts a team of experienced and dedicated cleaning professionals committed to delivering high-quality results. </li>
                            <li>Tailored Cleaning Solutions: Easy Bond Clean offers tailored cleaning solutions, understanding the specific needs of tenants, landlords, and property managers during the end-of-lease cleaning process.</li>
                            <li>Reliable and Efficient: Known for reliability and efficiency, Easy Bond Clean strives to make the transition at the end of a lease hassle-free for both tenants and property owners. </li>

                        </ul>
                        <motion.div whileHover={{ scale: 1.05, transition: { duration: 1 }, }} whileTap={{ scale: 0.9 }}>
                            <button className="custom-button"><a href="/contact">DISCOVER MORE</a></button>
                        </motion.div>
                    </div>
                    <div className='col-lg-6 col-12 why'>
                        <img src={why} alt="why" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Choose