import TrackVisibility from 'react-on-screen';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { StickyContainer, Sticky } from 'react-sticky';
import Sticky from 'react-sticky-el';

import './App.css';
import './scss/_general.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import First from './First';
import Second from './Second';
import Third from './Third'
import Header from './components/Header';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import Services from './views/Services';
import HowItWorks from './views/HowItWorks';
import Faq from './views/Faq';
import Projects from './views/Projects';
import Testimonials from './views/Testimonials';
import Choose from './views/Choose';
import Satisfaction from './views/satisfaction';
import AdminLogin from './views/admin/admin-login';
import Message from './views/admin/message';
import BlogForm from './views/admin/BlogForm';
import BlogDisplay from './views/admin/BlogDisplay';
import Blog from './views/Blog';
import Contact from './views/contactUs';
import Quoute1 from './components/Quoutes/Quoute1';
import Quoute2 from './components/Quoutes/Quoute2'
import Text1 from './views/Text1'
import Navbar from './components/Navbar';
import BigBlog from './views/BigBlog'

function Home() {
  return (
    <main>
      <Header />
      <First />
      <Second />
      <Quoute1 />
      <Services />
      <TrackVisibility once offset={200}>
        <HowItWorks />
      </TrackVisibility>
      <Text1 />
      <Quoute2 />
      <TrackVisibility once offset={200}>
        <Faq />
      </TrackVisibility>
      <Testimonials />
      <Footer />
      <Copyright />
    </main >
  )
}

function AboutUs() {
  return (
    <main>
      <Header />
      <div className='aboutCrumb'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <h1>ABOUT US</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Easy bond</a></li>
              <li className="breadcrumb-item active" aria-current="page">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
      <Second />
      <Choose />
      <Satisfaction />
      <Footer />
    </main>
  )
}

function Vlog() {
  return (
    <main>

      <Header />
      <div className='blogCrumb'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <h1> Blog section</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Easy Bond</a></li>
              <li className="breadcrumb-item active" aria-current="page">Blog</li>
            </ol>
          </nav>
        </div>
      </div>
      <Blog />
      <Footer />
    </main>
  )
}

function CleanService() {
  return (
    <main>

      <Header />
      <div className='cleanCrumb'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <h1> Cleaning Service</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Easy Bond</a></li>
              <li className="breadcrumb-item active" aria-current="page">Cleaning Service</li>
            </ol>
          </nav>
        </div>
      </div>
      <Projects />
      <Footer />
    </main>
  )
}

function ContactPage() {
  return (
    <main>
      <Header />
      <Contact />
      <Footer />
    </main>
  )
}

function BlogId() {
  return (
    <main>
      <Header />
      <div className='contactCrumb'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <h1>Blog Article</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Easy Bond</a></li>
              <li className="breadcrumb-item active" aria-current="page">Blog Article</li>
            </ol>
          </nav>
        </div>
      </div>
      <BigBlog />
      <Footer />
    </main>
  )

}


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/Panel" element={<Message />} />
      <Route path="/blogForm" element={<BlogForm />} />
      <Route path="/blog" element={<Vlog />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/BigBlog/:id" element={<BlogId />} />
      <Route path="/service" element={<CleanService />} />
      <Route path="/BlogDisplay" element={<BlogDisplay />} />
    </Routes>



  );
}

export default App;
