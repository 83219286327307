import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

import './Faq.scss'

const FAQ_DATA = [
  {
    id: 0,
    question: 'What sets your cleaning service apart from others?',
    answer: 'Our company takes pride in its unique approach to cleaning. We go beyond the surface to create an environment that not only looks clean but feels revitalized. Our attention to detail, commitment to quality, and personalized service distinguish us from others in the industry. '
  },
  {
    id: 1,
    question: 'what are the innovative cleaning techniques your company employs?',
    answer: 'Embracing cutting-edge cleaning methods is at the core of our service philosophy. We continually explore and implement innovative techniques to ensure efficiency and effectiveness. '
  },
  {
    id: 2,
    question: 'What is the primary goal of your cleaning service?',
    answer: 'Our foremost objective is twofold – to achieve utmost customer satisfaction and to ensure thorough and proper cleaning.'
  },
  {
    id: 3,
    question: 'Are your cleaning products safe and environmentally friendly?',
    answer: 'Yes, we prioritize the health and safety of our clients and the environment. If you have specific preferences or concerns, please let us know, and we will do our best to accommodate your needs.'
  }
]

const Faq = ({ isVisible }) => {
  return (
    <section id='faq' style={{ backgroundColor: '#F2F6FF' }} className={`py-5 ${isVisible ? 'anim-active' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="img-holder">
              <img src="images/faq_img02.png" alt="cleaner" />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="block-header mb-5">
              <h3>Frequently Asked Question from Our Clients</h3>
            </div>
            <Accordion defaultActiveKey={0}>
              {
                FAQ_DATA.map(item => {
                  return (
                    <Accordion.Item eventKey={item.id} key={item.id} className='mb-4'>
                      <Accordion.Header>
                        <div className="position-relative py-3 w-100" style={{ paddingRight: '55px' }}>
                          <strong>{item.question}</strong>
                          <div className="accordion-icon"></div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.answer}
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })
              }
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq