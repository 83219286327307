import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BigBlog.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SearchIcon from '@mui/icons-material/Search';
import Sticky from 'react-sticky-el';


const BigBlog = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ROUTE}/api/blog/${id}`);
                setBlog(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setError("Error fetching blog");
                setLoading(false);
            }
        };

        fetchBlog();
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!blog) {
        return <p>Blog not found</p>;
    }

    const categories = ['carpet cleaning', 'window cleaning', 'Steam cleaning', 'House cleaning'];

    const getCategoryImage = (category) => {
        switch (category) {
            case 'House cleaning':
                return process.env.PUBLIC_URL + '/images/img01.jpg';
            case 'Steam cleaning':
                return process.env.PUBLIC_URL + '/images/img02.jpg';
            case 'window cleaning':
                return process.env.PUBLIC_URL + '/images/img03.jpg';
            case 'carpet cleaning':
                return process.env.PUBLIC_URL + '/images/img11.jpg';
            default:
                return process.env.PUBLIC_URL + '/images/img01.jpg';
        };
    };

    return (
        <div className="bigBlog">
            <div className="container">
                <div className="row">

                    <div className="col-4 side-content">
                        <Sticky>
                            <div className="side-search">
                                <div className="search-bar">
                                    <Form>
                                        <Form.Control type="text" placeholder="Search..." />
                                    </Form>
                                </div>
                                <div className="search-icon">
                                    <Button variant="light-blue" type="submit">
                                        <SearchIcon />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                {console.log(blog.category)}
                            </div>
                            <div className="category-list" >
                                <ul >
                                    {categories.map((category) => (
                                        <li
                                            key={category}
                                            className={blog.category === category ? 'active-category' : ''}
                                        >
                                            {category}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </ Sticky >
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="image-container">
                            <img
                                src={getCategoryImage(blog.category)}
                                alt={blog.category}
                                className="category-image"
                            />
                        </div>
                        <div className="text-container">
                            <h1 className="blogTitle">{blog.title}</h1>
                            <p><b>Author: {blog.author} </b></p>
                            <p><b> Category: {blog.category}</b></p>
                            <div dangerouslySetInnerHTML={{ __html: blog.blog }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BigBlog;