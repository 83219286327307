import React from 'react'

import CustomCard from '../components/CustomCard'

const CARDS_DATA = [
  {
    id: 1,
    title: 'End of lease cleaning',
    description: 'Get comprehensive bond clean service for a smooth exit. We sanitize, scrub, and polish, leaving no corner untouched. Get your bond back hassle-free.'
  },
  {
    id: 2,
    title: 'Upholstery Cleaning',
    description: 'Revitalize your furniture and enhance your comfort with our top-notch upholstery cleaning services, ensuring a fresh and renewed ambiance.'
  },
  {
    id: 3,
    title: 'Carpet Cleaning',
    description: 'Restore your carpet’s brilliance. We deep-clean, remove stains, and eliminate allergens, breathing new life into your floors and improving indoor air quality.'
  },
  {
    id: 4,
    title: 'Window Cleaning',
    description: 'Let the world shine through crystal-clear windows. Elevate your space with our professional window cleaning service, providing clarity and brightness to every view.'
  },
  {
    id: 5,
    title: 'Bond Cleaning',
    description: 'Secure your bond back with perfection. We meticulously clean every inch, from walls to floors, guaranteeing landlord approval and a full bond refund.'
  },
  {
    id: 6,
    title: 'Power/Pressure Cleaning',
    description: 'Restore the beauty of your surfaces with our powerful pressure cleaning services. Say goodbye to dirt and grime, and welcome a refreshed and revitalized exterior for your property.'
  }
]

const Services = () => {
  return (
    <section id="services" className='text-center py-5' style={{ backgroundColor: '#F2F6FF' }}>
      <div className="container">
        <div className="block-header mb-5">
          <h2>Our services range:</h2>
        </div>
        <div className="list-holder">
          <ul className="services-list d-flex flex-wrap">
            {
              CARDS_DATA.map(cardItem => {
                return (
                  <li className='col-12 col-lg-4 mb-5 px-2' key={cardItem.id}>
                    <CustomCard className='h-100' title={cardItem.title} description={cardItem.description} />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Services