import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';  // Import Button from react-bootstrap
import './BlogDisplay.css';

import Panel from "../../components/Panel/Panel";

const BlogDisplay = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ROUTE}/api/blog`);
                setBlogs(response.data.blogs);
            } catch (error) {
                console.error("Error fetching blogs:", error);
                // Handle error state or display an error message to the user
            }
        };

        fetchBlogs();
    }, []);

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this blog?");
        if (!confirmed) {
            return; // If not confirmed, do nothing
        }
        try {
            await axios.delete(`${process.env.REACT_APP_API_ROUTE}/api/blog/${id}`);

            // After successful deletion, fetch and update the state to reflect the changes
            const response = await axios.get(`${process.env.REACT_APP_API_ROUTE}/api/blog`);
            setBlogs(response.data.blogs);
        } catch (error) {
            console.error("Error deleting blog:", error);
            // Handle error state or display an error message to the user
        }
    };

    return (
        <main id="blog">
            <div className="row">
                <div className="col-2 blog-panel">
                    <Panel />
                </div>
                <div className="col-10">
                    {[...blogs].reverse().map((blog, index) => (
                        <Card key={index} className=" col-lg-5 col-12">
                            <Card.Body>
                                <p>{blog.category}</p>
                                <p>{blog.id}</p>
                                <Card.Title><h2>{blog.title}</h2></Card.Title>
                                <Card.Text>
                                    <h5><b>Author:</b> {blog.author}</h5>
                                </Card.Text>
                                {/* Add a delete button with an onClick handler */}
                                <Button variant="danger" onClick={() => handleDelete(blog.id)}>
                                    Delete
                                </Button>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </main>
    );
}

export default BlogDisplay;
